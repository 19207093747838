<template lang="pug">
	skelet
		navHeader(slot='header', interface_name='agency')
		ul#sidebar-scroll.menu.menu-nav(slot='sidebar')
			li.menu-item(
				:class='{ active: $route.name === "agency-usersmanagment" }',
				v-if='$checkPermission("userManager", $store)'
				@click='$router.push("/agency/usersmanagment")',
				@click.middle.prevent='$openInNewTab("agency/usersmanagment")',
				@contextmenu.prevent='$openInNewTab("agency/usersmanagment")'
			)
				a
					v-icon(dark, small, left) people
					| Пользователи
			li.menu-item(
				:class='{ active: $route.name === "agency-advertiser" }', v-if='$checkPermission("accountManager", $store)'
				@click='$router.push("/agency")',
				@click.middle.prevent='$openInNewTab("agency")',
				@contextmenu.prevent='$openInNewTab("agency")'
			)
				a
					v-icon(dark, small, left) card_travel
					| Рекламодатели
			li.menu-item(
				:class='{ active: $route.name === "agency-table" }', v-if='$checkPermission("admin", $store)',
				@click='$router.push("/agency/table")',
				@click.middle.prevent='$openInNewTab("agency/table")',
				@contextmenu.prevent='$openInNewTab("agency/table")'
			)
				a
					v-icon(dark, small, left) insert_chart_outlined
					| Сводная таблица
			li.menu-item(
				:class='{ active: $route.name === "agency-report" }', v-if='$checkPermission("admin", $store)'
				@click='$router.push("/agency/report")',
				@click.middle.prevent='$openInNewTab("agency/report")',
				@contextmenu.prevent='$openInNewTab("agency/report")'
			)
				a
					v-icon(dark, small, left) mdi-signal-cellular-3
					| Отчёты
			li.menu-item(
				:class='{ active: $route.name === "radar-photo-surfaces" }',
				v-if="this.getUserInfo.role === 'Root'",
				@click='$router.push("/agency/photo/")'
			)
				a
					v-icon(dark, small, left) mdi-camera
					| Фото
			li.menu-item.mobile-userInfo(v-if='getSession.root' @click='$router.push("/root")')
				a
					v-icon(dark, small, left) navigate_before
					| Вернуться в root
			li.menu-item.space.mobile-userInfo(
				@click='$router.push("/")',
				@click.middle.prevent='$openInNewTab("login")',
				@contextmenu.prevent='$openInNewTab("login")'
			)
				a
					v-icon(dark, small, left) navigate_before
					| Выйти на главный экран
			li.menu-item.space.mobile-userInfo(
				@click='$router.push("/login"); Logout()',
				@click.middle.prevent='$openInNewTab("")',
				@contextmenu.prevent='$openInNewTab("")'
			)
				a
					v-icon(dark, small, left) navigate_before
					| Выйти
			.mobile-userInfo(style='position: absolute; bottom: 5px; width: 100%')
				v-btn.ma-2(
					width='220'
					height='40'
					color='accent',
					v-if='getSession.root',
					@click='$router.push("/root")'
				)
					v-icon(dark, small, left) navigate_before
					| Вернуться в root
		div.padding_content(slot='content')
			div(v-if='LoggedIn === true')
				router-view
				SelfInfo(:dialogFormVisible.sync='dialogFormVisible')
			.content.d-flex(
				v-show='!LoggedIn',
				:class='{ loading: !LoggedIn, "loading-lg": !LoggedIn }',
				style='width: 100%'
			)
		v-dialog(v-model='toggleModal', title='Запрос на пополнение баланса')
			v-text-field(@keyup.enter='sendMessage', type='number', v-model='msg', placeholder='Укажите на какую сумму')
			.flex-end.mt-2
				v-btn(color='primary', :disabled='msg === 0', @click='sendMessage') Запросить
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import SelfInfo from '@/components/SelfInfo';
import navHeader from '@/components/nav/header';
import skelet from '@/components/nav/skelet';
import loading from '@/mixins/loading_mixin';
import {CHECK_SESSION} from '@/store/const/auth';
import {ACCOUNTS, AGENCY_DATA, PROFILE, SUMMARY_INFO} from '@/store/const/root';
import {GET_ACCOUNT_DATA, SEND_MESSAGE} from '@/store/const/agency';
import {GET_TRANSACTIONS} from '@/store/const/finance';
import {LOGOUT} from '@/store/const/auth';

export default {
	name: 'LayoutAgency',
	mixins: [loading],
	components: {
		SelfInfo,
		navHeader,
		skelet,
	},
	data: function () {
		return {
			CheckSessionTimer: 0,
			LoginData: {
				login: '',
				password: '',
			},
			LoggedIn: false,
			Loading: false,
			toggleModal: false,
			Error: '',
			msg: '',
			SessionInvalid: false,
			bots: 0,
			dialogFormVisible: false,
		};
	},
	async mounted() {
		setTimeout(async () => {
			await this.CHECK_SESSION()
			if (!this.isError(CHECK_SESSION)) {
				this.LoggedIn = true;
				await this.PROFILE('agency');
				await this.InitData();
			}
		}, 200)
	},
	beforeDestroy() {
		clearInterval(this.CheckSessionTimer);
	},
	computed: {
		...mapGetters('Auth', ['getSession']),
		...mapGetters('User', ['getUserInfo'])
	},
	methods: {
		...mapActions('Auth', [CHECK_SESSION]),
		...mapActions('Root', [PROFILE, AGENCY_DATA, SUMMARY_INFO, ACCOUNTS]),
		...mapActions('Agency', [GET_ACCOUNT_DATA, SEND_MESSAGE]),
		...mapActions('Finance', [GET_TRANSACTIONS]),
		...mapMutations('Auth', [LOGOUT]),
    setLoadingActions() {
      this.actions = [
        CHECK_SESSION,
        AGENCY_DATA,
        SUMMARY_INFO,
        ACCOUNTS,
        GET_ACCOUNT_DATA
      ]
    },
		async sendMessage() {
			const sum = +this.msg;
			if (sum === 0 || this.msg === '') {
				this.$notify({
					title: 'Ошибка запроса пополнения',
					text: 'Необходимо указать сумму',
					type: 'error',
				});
				return;
			}
			
			const data = {
				message: `Просьба пополнить баланс на ${sum.toString()}`,
			};
			await this.SEND_MESSAGE(data)
			if (this.isError(SEND_MESSAGE)) {
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: 'При отправке запроса возникла ошибка!',
				});
				return
			}
			this.$notify({
				type: 'success',
				title: 'Успешно',
				text: `Запрос на сумму ${sum} успешно отправлен`,
			});
			this.toggleModal = false;
			this.msg = '';
		},
		Logout: function () {
			this.LOGOUT();
			this.LoggedIn = false;
			this.Loading = false;
		},
		async InitData() {
			let startDate = this.$moment().startOf('month').valueOf();
			let endDate = this.$moment().endOf('month').set({hour: 23, minute: 59, second: 59}).valueOf();
			await this.GET_ACCOUNT_DATA()
			await this.SUMMARY_INFO({
				session_type: 'agency',
				date: {
					start: startDate,
					stop: endDate,
				},
			})
			await this.GET_TRANSACTIONS({
				date: [startDate, endDate]
			})
		},
	},
};
</script>

<style lang="scss">
.container {
	max-width: inherit;
}

#sidebar-id {
	background: #334;
	color: #ddd;
}

.space {
	margin-bottom: 0.4rem;
}

.bots-percent {
	padding: 0.5em;
	color: #76838c;
	cursor: pointer;
	transition: all 0.5s;
}

.bots-percent:hover {
	color: #e0d3ff;
}

a {
	cursor: pointer;
}

.edit {
	color: rgb(118, 131, 140);
	padding-left: 0.3em;
	margin-bottom: 0.3em;
}

.edit:hover {
	color: white;
}
</style>
